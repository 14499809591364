import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import CookieConsent from "react-cookie-consent";

const Layout = props => {
  const { children } = props;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const title = props.title || site.siteMetadata.title;
  const [toggleNav, setToggleNav] = React.useState(false);
  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`} title="Home">
              {title}
            </Link>
          </div>
          
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link> &mdash;{" "}
        <Link to="/impressum" title="Impressum">
          Impressum
        </Link>{" "}
        &mdash;{" "}
        <Link to="/datenschutzhinweis" title="Datenschutzhinweis">
          Datenschutzhinweis
        </Link>{" "}
        &mdash; Built with{" "}
        <a
          href="https://gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
          title="GatsbyJS"
        >
          Gatsby
        </a>{" "}
      </footer>
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Ablehnen"
        cookieName="gatsby-gdpr-google-analytics"
      >
        Diese Seite nutzt Cookies für anonyme Statistiken. Bist Du damit
        einverstanden?
      </CookieConsent>
    </div>
  );
};

export default Layout;
